'use client';

import { useRef } from 'react';
import { useSelector } from 'react-redux';
import { Anchor } from '@geberit/gdds';

// styles
import { DummyBackToTopButton } from './back-to-top-button.styles';

// types
import type { FooterProps } from './footer.types';

// components
import { EditWrapper } from 'components/ContentCreator/EditWrapper';
import { Image as StaticImage } from 'components/image/image';
import { CmsLink } from 'components/Link/CmsLink';
import { SearchIndexOff } from 'components/SearchIndex/SearchIndexOff';
import { Translation } from 'components/Translation/Translation';
import GddsFooter from './GddsFooter';
import { BackToTopButton } from './back-to-top-button';

// utils
import { TPP_TYPE_TECHNICAL } from 'components/ContentCreator/tppConstants';
import { useGdds } from 'utils/hooks/use-gdds';
import {
  footerNavigationItemsSelector,
  footerPreviewIdSelector,
} from 'utils/selectors/footerNavigationSelectors';
import {
  footerCountrySwitchSelector,
  globalsServerSelector,
  showMegaMenuSelector,
  socialMediaItemsSelector,
} from 'utils/selectors/globalsSelectors';
import { useTracking } from 'utils/hooks/useTracking';
import { isEmpty } from 'utils/is-empty';
import { useBackToTop } from './use-back-to-top';
import { classNameBuilder } from 'utils/classNameBuilder';
import { decodingContent } from 'utils/decodingContent';
import { useIsLeadManagementLanding } from 'utils/hooks/use-is-lead-management-landing';

export default function Footer({ isCampus }: Readonly<FooterProps>) {
  const navigationItems = useSelector(footerNavigationItemsSelector);
  const previewId = useSelector(footerPreviewIdSelector);
  const socialMediaItems = useSelector(socialMediaItemsSelector);
  const countrySwitch = useSelector(footerCountrySwitchSelector);
  const showMegaMenu = useSelector(showMegaMenuSelector);
  const footerRef = useRef(null);
  const isGdds = useGdds();
  const track = useTracking();
  const { displayFixed, displayStatic, ref } = useBackToTop({
    offset: -32,
  });
  const server = useSelector(globalsServerSelector);
  const isLeadManagementLanding = useIsLeadManagementLanding(server);

  const trackOnClick = () => {
    if (showMegaMenu) {
      setTimeout(() => {
        window.scrollTo({ top: 0 });
      }, 200);
    }
    track.trackEvent({
      event: 'genericEvent',
      data: {
        category: 'Navigation',
        action: 'to-Top button',
        label: 'to-top',
        value: '0',
      },
    });
  };

  if (isGdds) {
    return (
      <GddsFooter
        {...{ navigationItems, socialMediaItems, previewId }}
        toTopButton={
          <>
            {displayStatic || (
              <Anchor
                onClick={trackOnClick}
                className={classNameBuilder('back-to-top-anchor', displayFixed && 'isFixed')}
                href="#to-top"
                aria-label="to the top"
              />
            )}
            {displayStatic && (
              <Anchor
                onClick={trackOnClick}
                className={classNameBuilder('back-to-top-anchor', displayStatic && 'isStatic')}
                href="#to-top"
                aria-label="to the top"
              />
            )}
            <DummyBackToTopButton ref={ref} />
          </>
        }
      />
    );
  }

  return (
    <SearchIndexOff>
      <EditWrapper previewId={previewId} editType={TPP_TYPE_TECHNICAL}>
        <footer className="c-footer" ref={footerRef}>
          <section className="grid-container">
            <div className="c-footer__wrapper">
              {!isLeadManagementLanding && (
                <>
                  <div className="c-footer__country-change">
                    <p>
                      <strong>
                        <Translation id="web20_footer_country_selector_label" />
                      </strong>
                      {countrySwitch && (
                        <CmsLink
                          link={{
                            target: countrySwitch.pageref,
                            type: 'internal_link',
                            showArrow: false,
                            window: isCampus ? '_blank' : undefined,
                          }}
                          footerAction="CountrySwitch"
                          footerLabel={countrySwitch.countryTranslation}
                        >
                          {countrySwitch.countryTranslation}
                        </CmsLink>
                      )}
                    </p>
                  </div>
                  <div className="c-footer__social ">
                    {!isEmpty(socialMediaItems) &&
                      socialMediaItems.map((item) => {
                        const link = {
                          target: item.url,
                          window: '_blank',
                          rel: 'noopener noreferrer',
                        };

                        return (
                          item.url !== '' && (
                            <CmsLink
                              link={link}
                              key={item.type}
                              socialTrackingName="Social-Icons"
                              socialTrackingIcon={
                                item.type.charAt(0).toUpperCase() + item.type.slice(1)
                              }
                            >
                              <StaticImage
                                src={`/images/social/${item.type}.svg`}
                                alt={item.type}
                                title={item.type}
                                staticImage
                              />
                            </CmsLink>
                          )
                        );
                      })}
                  </div>
                </>
              )}
              <div className="c-footer__nav">
                <p key="page-copy">
                  <Translation id="web20_page_copy" />
                </p>
                {!isEmpty(navigationItems) &&
                  navigationItems.map((item) => (
                    <CmsLink
                      link={{
                        text: item.label,
                        target: item.url,
                        window: isCampus ? '_blank' : item.window,
                        type: item.type,
                      }}
                      key={item.label}
                      footerAction="Disclaimer"
                      footerLabel={item.label}
                    >
                      {decodingContent(item.label)}
                    </CmsLink>
                  ))}
              </div>
            </div>
          </section>
          <BackToTopButton onClick={trackOnClick} />
        </footer>
      </EditWrapper>
    </SearchIndexOff>
  );
}
